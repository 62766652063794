import React, { useState } from 'react';
import { exportMedia } from '../../firebase.service';
import Button from '../button/Button';
import Spinner from '../spinner/Spinner';

const ExportMedia = ({ data }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [downloadURL, setDownloadURL] = useState(null); // État pour stocker l'URL de téléchargement

    const handleExport = async () => {
        try {
            setIsLoading(true);
            setDownloadURL(null); // Réinitialiser l'URL avant un nouvel export

            // Appel à la fonction Firebase pour générer le fichier ZIP
            const response = await exportMedia(data.images, data.videos);

            if (response && response.downloadURL) { // Vérification que 'response' et 'downloadURL' existent
                setDownloadURL(response.downloadURL); // Stocker l'URL si elle est retournée
            } else {
                console.error("La réponse ne contient pas 'downloadURL'");
            }

            localStorage.setItem('mediaExported', 'true');
        } catch (error) {
            console.error('Error generating zip file', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            {isLoading ? (
                <Spinner type='button' />
            ) : (
                <>
                    {/* Affichage du bouton de téléchargement si l'URL n'est pas encore générée */}
                    {!downloadURL && (
                        <Button onClick={handleExport} icon='download' text="Télécharger toutes mes photos et vidéos" theme="primary" />
                    )}

                    {/* Affichage du lien de téléchargement avec le style du bouton */}
                    {downloadURL && (
                        <a
                            href={downloadURL}
                            download="media.zip"
                            className="button primary" // Utiliser les classes du bouton
                            style={{
                                display: 'block',
                                padding: '10px 20px',
                                margin: '0 auto',
                                color: '#fff',
                                backgroundColor: '#007bff',
                                borderRadius: '4px',
                                textDecoration: 'none',
                                fontWeight: 'bold',
                                textAlign: 'center'
                            }}
                        >
                            Cliquez ici pour télécharger le fichier ZIP
                        </a>
                    )}
                </>
            )}
        </div>
    );
};

export default ExportMedia;
