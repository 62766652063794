import React, { useEffect, useState } from 'react';
import './MemoryLanding.scss';
import { getPageById, updateVisitData, getQrCodeData } from '../../../firebase.service';
import { format, set } from 'date-fns';
import { fr, enUS, es } from 'date-fns/locale';
import Spinner from '../../spinner/Spinner';
import Logo from '../../logo/Logo';
import share_white from '../../../assets/icons/share_white.png';
import world from '../../../assets/icons/world.png';
import MemoryTabs from '../memory-tabs/MemoryTabs';
import MemoryPassword from '../memory-password/MemoryPassword';
import { useNavigate } from 'react-router-dom';
import Saved from '../../saved/Saved';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';

import { v4 as uuidv4 } from 'uuid';
import LicenceMemory from '../../licence/LicenceMemory';

import catMask from '../../../assets/masks/cat.svg';
import dogMask from '../../../assets/masks/dog.svg';
import heartMask from '../../../assets/masks/heart.svg';

export default function MemoryLanding({ uid, partner }) {
    const [isLoading, setIsLoading] = useState(true);
    const [isPasswordOK, setIsPasswordOK] = useState(false);
    const [memoryData, setMemoryData] = useState(null);
    const [partnerInfo, setPartnerInfo] = useState(null);
    const [medallionData, setMedallionData] = useState(null);

    const [isMask, setIsMask] = useState(false);
    const [mask, setMask] = useState(null);

    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        const passwordStatus = localStorage.getItem(`memory-${uid}-passwordOK`);
        if (passwordStatus === 'true') {
            setIsPasswordOK(true);
        }
        getPageData();
    }, [uid]);

    const trackUserVisit = async (uid) => {
        const lastVisitKey = `lastVisit-${uid}`;
        const lastVisit = localStorage.getItem(lastVisitKey);
        let uniqueUser = localStorage.getItem('uniqueUserId');
        const now = new Date();

        if (!uniqueUser) {
            uniqueUser = uuidv4();
            localStorage.setItem('uniqueUserId', uniqueUser);
        }

        if (lastVisit) {
            const lastVisitDate = new Date(lastVisit);
            const timeDifference = now - lastVisitDate;

            if (timeDifference > 24 * 60 * 60 * 1000) {
                await updateVisitData(uid, uniqueUser, now, true);
                localStorage.setItem(lastVisitKey, now.toString());
            }
        } else {
            await updateVisitData(uid, uniqueUser, now, true);
            localStorage.setItem(lastVisitKey, now.toString());
        }
    };


    const getPageData = async () => {
        const dataPage = await getPageById(uid);
        if (partner && partner.length > 0) {
            setPartnerInfo(partner[0]);
        }
        setMemoryData(dataPage);
        if (dataPage.mask) {
            setIsMask(true);
            setMask(getMaskFromName(dataPage.mask));
        }
        await qrCodeLicence(dataPage.qrcodeID);
        setIsLoading(false);
        trackUserVisit(uid);
    };

    const getMaskFromName = (maskName) => {
        switch (maskName) {
            case 'catMask':
                return catMask;
            case 'dogMask':
                return dogMask;
            case 'heartMask':
                return heartMask;
            default:
                return null; // Ou un masque par défaut
        }
    };

    const qrCodeLicence = async (qrcodeID) => {
        const response = await getQrCodeData(qrcodeID);
        if (response) {
            setMedallionData(response);
        }
        // console.log(response);
    }

    function formatTimestampToRelativeDate(timestamp) {
        let date;
        let lang;
        if (i18n.language === 'fr') {
            lang = fr;
        } else if (i18n.language === 'es') {
            lang = es;
        } else if (i18n.language === 'en') {
            lang = enUS;
        } else {
            lang = fr;
        }

        if (timestamp && typeof timestamp.toDate === 'function') {
            date = timestamp.toDate();
        } else if (timestamp) {
            date = new Date(timestamp);
        } else {
            return '';
        }

        const utcDate = new Date(Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
        ));

        // REFONTE : ASSIGNER LANGUE A CHAQUE PAGE.

        return format(utcDate, 'd MMMM yyyy', { locale: lang });
    }


    const privatePasswordPage = () => {
        setIsPasswordOK(true);
        localStorage.setItem(`memory-${uid}-passwordOK`, 'true');
    };

    // const resetLocalStorage = () => {
    //     localStorage.removeItem(`memory-${uid}-passwordOK`);
    //     setIsPasswordOK(false);
    // };

    useEffect(() => {
        const redirection = localStorage.getItem('redirect');
        if (redirection) {
            localStorage.removeItem('redirect');
        }
    }, []);

    const openLocation = () => {
        if (memoryData.location && memoryData.location.coord) {
            const { lat, lng } = memoryData.location.coord;
            const location = `${lat},${lng}`;
            if (location) {
                const url = `https://www.google.com/maps/search/?api=1&query=${location}&maptype=satellite`;
                window.open(url, '_blank');
            }
        }
    };

    const handleShare = async () => {
        const url = 'https://app.dernierinstant.com/memory/' + memoryData.qrcodeID;
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Dernier Instant',
                    url: url,
                });
            } catch (error) {
                console.error('Erreur lors du partage :', error);
            }
        } else {
            try {
                await navigator.clipboard.writeText(url);
                activeSaved();
            } catch (error) {
                console.error('Erreur lors de la copie dans le presse-papiers :', error);
            }
        }
    };

    // alert saved
    const [isSaved, setIsSaved] = useState(false);
    const activeSaved = () => {
        setIsSaved(true);
        setTimeout(() => setIsSaved(false), 2000);
    };

    return (
        isLoading ? <Spinner /> :
            <div className='container'>
                <Saved value={isSaved} message={t('memoryLanding.copiedIntoClipboard')} />
                <div className='memory'>
                    <div className='memory-logo' onClick={() => navigate('/login')}>
                        <Logo />
                    </div>
                    <div className='memory-cover'>
                        <img src={memoryData && memoryData.coverURL ? memoryData.coverURL : 'https://flaviendev.b-cdn.net/banner_dark.jpg'} alt='cover' />
                        {partnerInfo && (
                            <div className='partner-container' style={{ backgroundColor: partnerInfo.color }}>
                                <img onClick={() => window.open(partnerInfo.url, '_blank')} src={partnerInfo.logo} alt='partner logo' />
                            </div>
                        )}
                    </div>
                    <div className='content'>
                        {memoryData && memoryData.status === 'private' && !isPasswordOK ? (
                            <MemoryPassword data={memoryData} password={memoryData.password} onValid={privatePasswordPage} />
                        ) : (

                            <>
                                <div className='memory-photo'>
                                    <img
                                        className={isMask ? '' : 'default'}
                                        src={memoryData && memoryData.photoURL ? memoryData.photoURL : 'https://flaviendev.b-cdn.net/blank_profile.jpg'}
                                        style={isMask ? { maskImage: `url(${mask})`, WebkitMaskImage: `url(${mask})` } : {}}
                                        alt='memory'
                                    />

                                </div>
                                <div className='memory-name'>
                                    <h1>{memoryData && memoryData.name}</h1>
                                </div>
                                <div className='memory-date'>
                                    {/* Exception date page Oliver Marchais : 11 février 1964 - 25 janvier 2024 */}
                                    {uid === '8A0IaYubo5EROKvFSgeL' ? <p>11 février 1964 - 25 janvier 2024</p> : (
                                        <>
                                            {memoryData && memoryData.birth && `${formatTimestampToRelativeDate(memoryData.birth)} `}
                                            {memoryData && memoryData.death && ` - ${formatTimestampToRelativeDate(memoryData.death)}`}
                                            {memoryData && memoryData.location && (<small className='mt-20' style={{ display: 'block' }}> {memoryData.location.adresse.ville}</small>)}
                                        </>
                                    )}
                                </div>

                                {memoryData && (memoryData.location || memoryData.isShare) &&
                                    <div className='memory-actions'>
                                        {memoryData && memoryData.isShare && <button onClick={handleShare}><img src={share_white} alt='share' /> {t('memoryLanding.share')}</button>}
                                        {memoryData && memoryData.location && memoryData.isShare && <span className='divider'></span>}
                                        {memoryData && memoryData.location && <button onClick={openLocation}><img src={world} alt='location' /> {t('memoryLanding.location')}</button>}
                                    </div>
                                }
                                <MemoryTabs data={memoryData} medallionData={medallionData && medallionData} />
                            </>

                        )}
                    </div>

                    {partnerInfo && (
                        <div className='memory-footer-partner'>
                            {t('memoryLanding.partnerWith')} <a href={partnerInfo.url} target='_blank' rel='noreferrer'>{partnerInfo.short_url}</a>
                        </div>
                    )}
                    <div className='memory-footer'>
                        {t('memoryLanding.by')} <a href='https://dernierinstant.com' target='_blank' rel='noreferrer'>Dernier Instant</a>
                    </div>
                </div>
            </div>
    );
}
