import { sendPasswordResetEmail } from "firebase/auth";
import { ref as storageRef, deleteObject } from "firebase/storage";
import { GoogleAuthProvider, auth, createUserWithEmailAndPassword, db, signInWithEmailAndPassword, signInWithPopup, signOut, imageDb, functions, httpsCallable } from "./firebase.config";
import { addDoc, arrayRemove, arrayUnion, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { connectFunctionsEmulator } from "firebase/functions";
// import { loadStripe } from "@stripe/stripe-js";



// get qr code from code
export const qrCodeCheck = async (code) => {
    try {
        const userDoc = await getDoc(doc(db, "qrcodes", code));
        return userDoc.exists() ? userDoc.data() : null;
    } catch (error) {
        console.error("Error fetching user", error);
        return null;
    }
};

// get entreprise from ref
export const entrepriseCheck = async (ref) => {
    try {
        const q = query(collection(db, "entreprise"), where("ref", "==", ref));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const results = [];
            querySnapshot.docs.forEach(doc => {
                results.push(doc.data());
            });
            return results;
        }
        return [];
    } catch (error) {
        console.error("Error fetching code", error);
        return null;
    }
};

// FUNCTION OT CHECK HOW MANY CONVERSION PROFILE
export const countEmptyOrMissingImagePages = async () => {
    try {
        const q = query(collection(db, "pages"), where("images", "==", []));
        const querySnapshot = await getDocs(q);

        return querySnapshot.size;
    } catch (error) {
        console.error("Error fetching documents", error);
        return null;
    }
};

// update user name
export const updateUserName = async (name) => {
    try {
        const userRef = doc(db, "users", auth.currentUser.uid);
        await updateDoc(userRef, {
            displayName: name
        });
        return { success: true };
    } catch (error) {
        return { success: false, error: error };
    }
};

// update page field
export const updatePageField = async (id, fieldName, fieldValue) => {
    try {
        const userRef = doc(db, "pages", id);
        await updateDoc(userRef, {
            [fieldName]: fieldValue
        });
        return { success: true };
    } catch (error) {
        return { success: false, error: error.message };
    }
};

// delete links
export const deleteLink = async (id, fieldName, linkToRemove) => {
    try {
        const pageRef = doc(db, "pages", id);
        const pageSnapshot = await getDoc(pageRef);

        if (!pageSnapshot.exists()) {
            throw new Error("Page non trouvée");
        }

        await updateDoc(pageRef, {
            [fieldName]: arrayRemove(linkToRemove)
        });

        return { success: true };
    } catch (error) {
        return { success: false, error: error.message };
    }
};

// create comment
export const createComment = async (pageID, data) => {
    try {
        const userRef = doc(db, "pages", pageID);
        await updateDoc(userRef, {
            comment: arrayUnion(data)
        });
        return { success: true };
    } catch (error) {
        return { success: false, error: error.message };
    }
};

// delete comment
export const removeComment = async (pageID, data) => {
    try {
        const userRef = doc(db, "pages", pageID);
        await updateDoc(userRef, {
            comment: arrayRemove(data)
        });
        return { success: true };
    } catch (error) {
        return { success: false, error: error.message };
    }
};

// delete answer
export const removeAnswerFromComment = async (pageID, commentID, answerID) => {
    try {
        const pageRef = doc(db, "pages", pageID);
        const pageSnap = await getDoc(pageRef);

        if (pageSnap.exists()) {
            const comments = pageSnap.data().comment || [];
            const commentIndex = comments.findIndex(c => c.id === commentID);

            if (commentIndex !== -1) {
                // Copie du commentaire pour éviter toute mutation directe
                const updatedComment = { ...comments[commentIndex] };
                // Filtrage des réponses pour supprimer celle avec l'ID spécifié
                const updatedAnswers = updatedComment.answer.filter(a => a.id !== answerID);

                // Mise à jour des réponses dans le commentaire
                updatedComment.answer = updatedAnswers;

                // Mise à jour du tableau de commentaires avec le commentaire modifié
                const updatedComments = [
                    ...comments.slice(0, commentIndex),
                    updatedComment,
                    ...comments.slice(commentIndex + 1)
                ];

                await updateDoc(pageRef, {
                    comment: updatedComments // Mise à jour du tableau de commentaires dans Firestore
                });

                return { success: true };
            } else {
                return { success: false, error: "Comment not found" };
            }
        } else {
            return { success: false, error: "Page not found" };
        }
    } catch (error) {
        return { success: false, error: error.message };
    }
};

// Fonction pour ajouter une réponse à un commentaire spécifique
export const addAnswerToComment = async (pageID, commentID, answerData) => {
    try {
        const pageRef = doc(db, "pages", pageID);
        const pageSnap = await getDoc(pageRef);

        if (pageSnap.exists()) {
            const comments = pageSnap.data().comment || [];
            const commentIndex = comments.findIndex(c => c.id === commentID);

            if (commentIndex !== -1) {
                // Copie du commentaire pour éviter toute mutation directe
                const updatedComment = { ...comments[commentIndex] };
                // Ajout de la nouvelle réponse au tableau `answer` du commentaire
                updatedComment.answer = [...updatedComment.answer, answerData];

                // Mise à jour du tableau de commentaires avec le commentaire modifié
                const updatedComments = [
                    ...comments.slice(0, commentIndex),
                    updatedComment,
                    ...comments.slice(commentIndex + 1)
                ];

                await updateDoc(pageRef, {
                    comment: updatedComments // Mise à jour du tableau de commentaires dans Firestore
                });

                return { success: true };
            } else {
                return { success: false, error: "Comment not found" };
            }
        } else {
            return { success: false, error: "Page not found" };
        }
    } catch (error) {
        return { success: false, error: error.message };
    }
};

// Fonction pour liker ou disliker un commentaire spécifique
export const toggleLikeOnComment = async (pageID, commentID, userID) => {
    try {
        const pageRef = doc(db, "pages", pageID);
        const pageSnap = await getDoc(pageRef);

        if (pageSnap.exists()) {
            const comments = pageSnap.data().comment || [];
            const commentIndex = comments.findIndex(c => c.id === commentID);

            if (commentIndex !== -1) {
                // Copie du commentaire pour éviter toute mutation directe
                const updatedComment = { ...comments[commentIndex] };

                const likeIndex = updatedComment.like.indexOf(userID);
                if (likeIndex === -1) {
                    // L'utilisateur n'a pas encore liké le commentaire, ajoutez son ID
                    updatedComment.like.push(userID);
                } else {
                    // L'utilisateur a déjà liké le commentaire, retirez son like
                    updatedComment.like.splice(likeIndex, 1);
                }

                // Mise à jour du tableau de commentaires avec le commentaire modifié
                const updatedComments = [
                    ...comments.slice(0, commentIndex),
                    updatedComment,
                    ...comments.slice(commentIndex + 1)
                ];

                await updateDoc(pageRef, {
                    comment: updatedComments // Mise à jour du tableau de commentaires dans Firestore
                });

                return { success: true };
            } else {
                return { success: false, error: "Comment not found" };
            }
        } else {
            return { success: false, error: "Page not found" };
        }
    } catch (error) {
        return { success: false, error: error.message };
    }
};

// Fonction pour marquer un commentaire comme modéré
export const moderateComment = async (pageID, commentID) => {
    try {
        const pageRef = doc(db, "pages", pageID);
        const pageSnap = await getDoc(pageRef);

        if (pageSnap.exists()) {
            const comments = pageSnap.data().comment || [];
            const commentIndex = comments.findIndex(c => c.id === commentID);

            if (commentIndex !== -1) {
                // Copie du commentaire pour éviter toute mutation directe
                const updatedComment = { ...comments[commentIndex] };
                // Modification de la valeur de 'moderated' à true
                updatedComment.moderated = true;

                // Mise à jour du tableau de commentaires avec le commentaire modifié
                const updatedComments = [
                    ...comments.slice(0, commentIndex),
                    updatedComment,
                    ...comments.slice(commentIndex + 1)
                ];

                await updateDoc(pageRef, {
                    comment: updatedComments // Mise à jour du tableau de commentaires dans Firestore
                });

                return { success: true };
            } else {
                return { success: false, error: "Comment not found" };
            }
        } else {
            return { success: false, error: "Page not found" };
        }
    } catch (error) {
        return { success: false, error: error.message };
    }
};

// update animal mask
export const updateAnimalMask = async (name, pageID) => {
    try {
        const userRef = doc(db, "pages", pageID);
        await updateDoc(userRef, {
            mask: name
        });
        return { success: true };
    } catch (error) {
        return { success: false, error: error };
    }
};

// create page
export const createPage = async (name, status) => {
    try {
        const pagesCollectionRef = collection(db, "pages");
        const docRef = await addDoc(pagesCollectionRef, {
            owner: auth.currentUser.uid,
            name: name,
            status: status,
            created: new Date(),
            qrcodeID: null,
            isComment: true,
            isModerate: true,
            isShare: true,
            password: Math.floor(Math.random() * 9999)
        });
        // update page id
        await setDoc(docRef, { id: docRef.id }, { merge: true });

        return docRef.id;
    } catch (error) {
        console.error("Error creating page document", error);
        return null;
    }
};

// Supprimer la page et ses images associées
export const deletePage = async (id) => {
    try {
        // Référence du document de la page
        const pageRef = doc(db, "pages", id);

        // Récupérer le document de la page
        const pageSnapshot = await getDoc(pageRef);
        if (!pageSnapshot.exists()) {
            throw new Error("Page non trouvée");
        }
        const pageData = pageSnapshot.data();

        // Tableau pour collecter les promesses de suppression des images
        const imageDeletePromises = [];

        // Supprimer la coverURL et photoURL si elles existent
        if (pageData.coverURL) {
            const coverRef = storageRef(imageDb, pageData.coverURL);
            imageDeletePromises.push(deleteObject(coverRef));
        }
        if (pageData.photoURL) {
            const photoRef = storageRef(imageDb, pageData.photoURL);
            imageDeletePromises.push(deleteObject(photoRef));
        }

        // Supprimer toutes les images dans le tableau 'images'
        if (pageData.images && Array.isArray(pageData.images)) {
            pageData.images.forEach((imageUrl) => {
                const imageRef = storageRef(imageDb, imageUrl);
                imageDeletePromises.push(deleteObject(imageRef));
            });
        }

        // Utiliser Promise.allSettled pour attendre que toutes les promesses soient réglées
        const results = await Promise.allSettled(imageDeletePromises);

        // Vous pouvez ici gérer ou logger les résultats individuellement
        results.forEach((result, index) => {
            if (result.status === 'rejected') {
                console.error(`Erreur lors de la suppression de l'image à l'index ${index}: ${result.reason}`);
            }
        });

        // Supprimer le document de la page de Firestore
        await deleteDoc(pageRef);

        return { success: true };
    } catch (error) {
        console.error(`Erreur lors de la suppression de la page et de ses images`, error);
        return { success: false, error: error.message };
    }
};

export const updateVisitData = async (uid, userId, now, incrementVisit) => {
    const updateVisitsFunction = httpsCallable(functions, 'updateVisitData');
    try {
        await updateVisitsFunction({ uid, userId, now, incrementVisit });
    } catch (error) {
        console.error('Error updating visits', error);
    }
};

/**
 * Fonction pour vérifier si un utilisateur a des abonnements actifs
 * @returns {Promise<Array>} Un tableau d'objets d'abonnement
 * @throws {Error} Si une erreur se produit lors de la récupération des données
 * @example
 * const activeSubscriptions = await getActiveSubscriptions();
 * console.log(activeSubscriptions);
 */

export const getAllStripeSubscriptions = async (userId) => {
    const subscriptionsRef = collection(db, 'stripe_webhooks');
    const q = query(subscriptionsRef);
    
    const querySnapshot = await getDocs(q);
    const subscriptions = [];
    querySnapshot.forEach((doc) => {
        const data = doc.data();
        const filteredEvents = data.events.filter(event => event.user_id === userId);
        if (filteredEvents.length > 0) {
            subscriptions.push({ ...data, events: filteredEvents });
        }
    });

    return subscriptions;
};

export const displayProductName = (product) => {
    // +50 photos = prod_QDXB7QmBXfkHMj
    // +100 photos = prod_QGzJv1RImZaNn5
    // +200 photos = prod_QGvLpqVYSUDU33
    // +500 photos = prod_QGzJtJ0UyXAXI7
    // +5 vidéos = prod_QGvJllXxu5hVS7
    // +10 vidéos = prod_QGvKLbSqnC5Q7R
    // +20 vidéos = prod_QGzJhLC4kfDPrS
    // +50 vidéos = prod_QGzKzE4ixZWLI6

    if (product === 'prod_QTzoyC91AxIvhG') {
        return '+50 photos';
    } else if (product === 'prod_QTzoXBV5frQ4Jx') {
        return '+100 photos';
    } else if (product === 'prod_QTzpFybNWLcKpQ') {
        return '+200 photos';
    } else if (product === 'prod_QTzpafcO6f1I9q') {
        return '+500 photos';
    } else if (product === 'prod_QGvJllXxu5hVS7') {
        return '+5 vidéos';
    } else if (product === 'prod_QGvKLbSqnC5Q7R') {
        return '+10 vidéos';
    } else if (product === 'prod_QGzJhLC4kfDPrS') {
        return '+20 vidéos';
    } else if (product === 'prod_QGzKzE4ixZWLI6') {
        return '+50 vidéos';
    } else {
        return '';
    }
}

export const exportMedia = async (imageUrls, videoUrls) => {
    const generateZip = httpsCallable(functions, 'generateZip');
    try {
        const result = await generateZip({ imageUrls, videoUrls });
        
        // Vérifiez que 'downloadURL' est dans la réponse
        if (result.data && result.data.downloadURL) {
            return result.data;  // Retourner l'objet contenant 'downloadURL'
        } else {
            throw new Error("La réponse de la Cloud Function ne contient pas 'downloadURL'");
        }
    } catch (error) {
        console.error('Error generating zip file', error);
        throw error;
    }
};

export const getAllSubscriptions = async () => {
    const getAllSubscriptionsFunction = httpsCallable(functions, 'getAllSubscriptions');
    try {
        const result = await getAllSubscriptionsFunction();
        return result.data.subscriptions;
    } catch (error) {
        console.error('Error fetching all subscriptions', error);
        throw error;
    }
};

export const getSubsRules = async () => {
    const getSubscriptionsRulesFunction = httpsCallable(functions, 'getSubscriptionsRules');
    try {
        const result = await getSubscriptionsRulesFunction();
        return result.data.rules;
    } catch (error) {
        console.error('Error fetching all subscriptions rules', error);
        throw error;
    }
};

export const checkSubscription = async (id) => {
    try {
        const q = query(collection(db, "subscriptions"));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const results = [];
            querySnapshot.docs.forEach(doc => {
                results.push(doc.data());
            });
            return results;
        }
        return [];
    } catch (error) {
        console.error("Error fetching code", error);
        return null;
    }
};

export const updateUserStatus = async (id, status, state) => {
    try {
        const userRef = doc(db, "users", id);
        await updateDoc(userRef, {
            status: status,
            state: state
        });
        return { success: true };
    } catch (error) {
        return { success: false, error: error.message };
    }
};

export const getQrCodeData = async (id) => {
    try {
        const qrCodeDoc = await getDoc(doc(db, "qrcodes", id));
        return qrCodeDoc.exists() ? qrCodeDoc.data() : null;
    } catch (error) {
        console.error("Error fetching user", error);
        return null;
    }
};

export const updateQrCodeLicenceStatus = async (id, subscriptionID, subscriptionStatus) => {
    const qrCodeDocRef = doc(db, "qrcodes", id);
    await updateDoc(qrCodeDocRef, { subscriptionID: subscriptionID, subscriptionStatus: subscriptionStatus });
}

export const removeLicenceFromQrCode = async (id) => {
    const qrCodeDocRef = doc(db, "qrcodes", id);
    await updateDoc(qrCodeDocRef, { subscriptionID: null, subscriptionStatus: null });
}

export const checkIfLicenceExist = async (id) => {
    try {
        const q = query(collection(db, "qrcodes"), where("subscriptionID", "==", id));
        const querySnapshot = await getDocs(q);
        return !querySnapshot.empty; // Retourne true si des documents existent
    } catch (error) {
        console.error("Error fetching code", error);
        return false; // En cas d'erreur, retourne false
    }
}

export const licenceDataBySubscriptionID = async (id) => {
    try {
        const q = query(collection(db, "qrcodes"), where("subscriptionID", "==", id));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const results = [];
            querySnapshot.docs.forEach(doc => {
                results.push(doc.data());
            });
            return results;
        }
        return [];
    } catch (error) {
        console.error("Error fetching code", error);
        return null;
    }
}



// link qrcode to page
export const linkQrCodeToPage = async (idPage, qrCode) => {
    try {
        const pageRef = doc(db, "pages", idPage);
        await updateDoc(pageRef, {
            qrcodeID: qrCode
        });

        const uid = auth.currentUser.uid;
        const qrCodeRef = doc(db, "qrcodes", qrCode);
        await updateDoc(qrCodeRef, {
            available: false,
            owner: uid,
            page: idPage,
        });

        return { success: true };
    } catch (error) {
        return { success: false, error: error };
    }
};

// get user pages
export const checkUserPage = async () => {
    const uid = auth.currentUser.uid;
    try {
        const q = query(collection(db, "pages"), where("owner", "==", uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const results = [];
            querySnapshot.docs.forEach(doc => {
                results.push(doc.data());
            });
            return results;
        }
        return [];
    } catch (error) {
        console.error("Error fetching code", error);
        return null;
    }
}

// // WHITELIST
// export const checkIfUserIsOnWhitelist = async () => {
//     const uid = auth.currentUser.uid;
//     try {
//         const q = query(collection(db, "pages"), where("owner", "==", uid));
//         const querySnapshot = await getDocs(q);

//         if (!querySnapshot.empty) {
//             const results = [];
//             querySnapshot.docs.forEach(doc => {
//                 results.push(doc.data());
//             });
//             return results;
//         }
//         return [];
//     } catch (error) {
//         console.error("Error fetching code", error);
//         return null;
//     }
// }

// get user by id
export const getUserById = async (id) => {
    try {
        const userDoc = await getDoc(doc(db, "users", id));
        return userDoc.exists() ? userDoc.data() : null;
    } catch (error) {
        console.error("Error fetching user", error);
        return null;
    }
};

// get page by id
export const getPageById = async (id) => {
    try {
        const pageDoc = await getDoc(doc(db, "pages", id));
        return pageDoc.exists() ? pageDoc.data() : null;
    } catch (error) {
        console.error("Error fetching user", error);
        return null;
    }
};

// update video
export const updateVideo = async (newVideoUrl, pageId = null) => {
    // const uid = auth.currentUser.uid;

    try {
        if (!newVideoUrl) {
            throw new Error("URL est undefined");
        }

        const docRef = doc(db, `pages/${pageId}`);

        const docSnapshot = await getDoc(docRef);
        if (!docSnapshot.exists()) {
            throw new Error("Document non trouvé");
        }

        await updateDoc(docRef, {
            videos: arrayUnion(newVideoUrl)
        });

        return { success: true };
    } catch (error) {
        console.log(`Erreur lors de la mise à jour de la video`, error);
        return { success: false, error: error.message };
    }
};

// add video youtube
export const addYoutubeVideo = async (newVideoUrl, pageId = null) => {
    // const uid = auth.currentUser.uid;

    try {
        if (!newVideoUrl) {
            throw new Error("URL est undefined");
        }

        const docRef = doc(db, `pages/${pageId}`);

        const docSnapshot = await getDoc(docRef);
        if (!docSnapshot.exists()) {
            throw new Error("Document non trouvé");
        }

        await updateDoc(docRef, {
            videos_youtube: arrayUnion(newVideoUrl)
        });

        return { success: true };
    } catch (error) {
        console.log(`Erreur lors de la mise à jour de la video`, error);
        return { success: false, error: error.message };
    }
};

// delete youtube video
export const deleteYoutubeVideo = async (videoUrl, pageId = null) => {
    try {
        if (!videoUrl) {
            throw new Error("URL de la video est undefined");
        }

        const docRef = doc(db, `pages/${pageId}`);
        const updateField = { videos_youtube: arrayRemove(videoUrl) };
        await updateDoc(docRef, updateField);

        return { success: true };
    } catch (error) {
        console.log(`Erreur lors de la suppression de la vidéo`, error);
        return { success: false, error: error.message };
    }
};

// delete video
export const deleteVideo = async (videoUrl, pageId = null) => {
    try {
        if (!videoUrl) {
            throw new Error("URL de la video est undefined");
        }

        const docRef = doc(db, `pages/${pageId}`);
        const updateField = { videos: arrayRemove(videoUrl) };
        await updateDoc(docRef, updateField);

        const videoRef = storageRef(imageDb, videoUrl);
        await deleteObject(videoRef);

        return { success: true };
    } catch (error) {
        console.log(`Erreur lors de la suppression de la vidéo`, error);
        return { success: false, error: error.message };
    }
};

// update picture
export const updateImage = async (type, newImageUrl, pageId = null) => {
    const uid = auth.currentUser.uid;

    try {
        if (!newImageUrl) {
            throw new Error("URL est undefined");
        }

        let docRef, oldImageUrl;

        switch (type) {
            case 'user':
                docRef = doc(db, `users/${uid}`);
                break;
            case 'page':
                if (!pageId) throw new Error("pageId est nécessaire pour le type 'page'");
                docRef = doc(db, `pages/${pageId}`);
                break;
            case 'cover':
                if (!pageId) throw new Error("pageId est nécessaire pour le type 'cover'");
                docRef = doc(db, `pages/${pageId}`);
                break;
            case 'main':
                if (!pageId) throw new Error("pageId est nécessaire pour le type 'main'");
                docRef = doc(db, `pages/${pageId}`);
                break;
            default:
                throw new Error("Type d'image non pris en charge");
        }

        const docSnapshot = await getDoc(docRef);
        if (!docSnapshot.exists()) {
            throw new Error("Document non trouvé");
        }
        const docData = docSnapshot.data();

        if (type === 'user' || type === 'cover' || type === 'main') {
            oldImageUrl = type === 'user' ? docData.photoURL : (type === 'cover' ? docData.coverURL : docData.photoURL);
            const updateField = type === 'user' ? { photoURL: newImageUrl } : (type === 'cover' ? { coverURL: newImageUrl } : { photoURL: newImageUrl });
            await updateDoc(docRef, updateField);
        } else if (type === 'page') {
            await updateDoc(docRef, {
                images: arrayUnion(newImageUrl)
            });
        }

        if (oldImageUrl) {
            const oldImageRef = storageRef(imageDb, oldImageUrl);
            await deleteObject(oldImageRef);
        }

        return { success: true };
    } catch (error) {
        console.log(`Erreur lors de la mise à jour de l'image (${type})`, error);
        return { success: false, error: error.message };
    }
};

// album
export const createAlbum = async (pageId, albumName, albumId, createdAt) => {
    try {
        const pageRef = doc(db, "pages", pageId);
        await updateDoc(pageRef, {
            albums: arrayUnion({ id: albumId, name: albumName, publicIds: [], createdAt })
        });
        return { success: true };
    } catch (error) {
        return { success: false, error: error.message };
    }
};

export const addImageToAlbum = async (pageId, albumId, imageUrls) => {
    console.log('addImageToAlbum called with:', { pageId, albumId, imageUrls });

    try {
        const pageRef = doc(db, "pages", pageId);
        const pageDoc = await getDoc(pageRef);

        if (!pageDoc.exists()) {
            throw new Error('Page not found');
        }

        const pageData = pageDoc.data();
        const albumIndex = pageData.albums.findIndex(album => album.id === albumId);

        if (albumIndex === -1) {
            throw new Error('Album not found');
        }

        const updatedAlbums = [...pageData.albums];
        updatedAlbums[albumIndex].publicIds = [...updatedAlbums[albumIndex].publicIds, ...imageUrls];

        await updateDoc(pageRef, { albums: updatedAlbums });

        console.log('Firestore update successful');
        return { success: true };
    } catch (error) {
        console.error('Error in addImageToAlbum:', error);
        return { success: false, error: error.message };
    }
};

export const updateImageOrder = async (pageId, albumId, newOrder) => {
    try {
        const pageRef = doc(db, "pages", pageId);
        const pageDoc = await getDoc(pageRef);

        if (pageDoc.exists()) {
            const pageData = pageDoc.data();
            const updatedAlbums = pageData.albums.map(album => {
                if (album.id === albumId) {
                    return {
                        ...album,
                        publicIds: newOrder
                    };
                }
                return album;
            });

            await updateDoc(pageRef, {
                albums: updatedAlbums
            });

            return { success: true };
        } else {
            throw new Error('Page not found');
        }
    } catch (error) {
        return { success: false, error: error.message };
    }
};

export const removeImageFromAlbum = async (pageId, albumId, imageUrl) => {
    console.log('removeImageFromAlbum called with:', { pageId, albumId, imageUrl });

    try {
        const pageRef = doc(db, "pages", pageId);
        const pageDoc = await getDoc(pageRef);

        if (!pageDoc.exists()) {
            throw new Error('Page not found');
        }

        const pageData = pageDoc.data();
        const albumIndex = pageData.albums.findIndex(album => album.id === albumId);

        if (albumIndex === -1) {
            throw new Error('Album not found');
        }

        const updatedAlbums = [...pageData.albums];
        updatedAlbums[albumIndex].publicIds = updatedAlbums[albumIndex].publicIds.filter(id => id !== imageUrl);

        await updateDoc(pageRef, { albums: updatedAlbums });

        console.log('Firestore update successful');
        return { success: true };
    } catch (error) {
        console.error('Error in removeImageFromAlbum:', error);
        return { success: false, error: error.message };
    }
};

export const deleteAlbum = async (pageId, albumId, publicIds) => {
    console.log('deleteAlbum called with:', { pageId, albumId, publicIds });

    try {
        // Supprimer toutes les images de Cloudinary
        for (const publicId of publicIds) {
            await deleteCloudinaryImage(publicId);
        }

        const pageRef = doc(db, "pages", pageId);
        const pageDoc = await getDoc(pageRef);

        if (!pageDoc.exists()) {
            throw new Error('Page not found');
        }

        const pageData = pageDoc.data();
        const updatedAlbums = pageData.albums.filter(album => album.id !== albumId);

        await updateDoc(pageRef, { albums: updatedAlbums });

        console.log('Album and images deleted successfully');
        return { success: true };
    } catch (error) {
        console.error('Error in deleteAlbum:', error);
        return { success: false, error: error.message };
    }
};

export const updateAlbumName = async (pageId, albumId, newName) => {
    try {
        const pageRef = doc(db, "pages", pageId);
        const pageDoc = await getDoc(pageRef);

        if (pageDoc.exists()) {
            const pageData = pageDoc.data();
            const albumIndex = pageData.albums.findIndex(album => album.id === albumId);

            if (albumIndex === -1) throw new Error('Album not found');

            const updatedAlbums = [...pageData.albums];
            updatedAlbums[albumIndex].name = newName;

            await updateDoc(pageRef, {
                albums: updatedAlbums
            });

            return { success: true };
        } else {
            throw new Error('Page not found');
        }
    } catch (error) {
        return { success: false, error: error.message };
    }
};

// delete image cloudinary
// connectFunctionsEmulator(functions, 'localhost', 5001);

export const deleteCloudinaryImage = async (publicId) => {
    const deleteImageFunction = httpsCallable(functions, 'deleteCloudinaryImage');
    console.log('Deleting Cloudinary image:', publicId);
    try {
        const result = await deleteImageFunction({ public_id: publicId });
        console.log('Cloudinary image deleted:', result);
        return result;
    } catch (error) {
        console.error('Error deleting image:', error);
    }
};

export const deleteCloudinaryVideo = async (publicId) => {
    const deleteVideoFunction = httpsCallable(functions, 'deleteCloudinaryVideo');
    console.log('Deleting Cloudinary image:', publicId);
    try {
        const result = await deleteVideoFunction({ public_id: publicId });
        console.log('Cloudinary video deleted:', result);
        return result;
    } catch (error) {
        console.error('Error deleting video:', error);
    }
};

export const deleteVideoCloudinary = async (videoUrl, pageId) => {
    try {
        if (!videoUrl) {
            throw new Error("URL de la video est undefined");
        }

        const videoDel = await deleteCloudinaryVideo(videoUrl);
        console.log('videoDel', videoDel);

        const docRef = doc(db, `pages/${pageId}`);
        const updateField = { videos: arrayRemove(videoUrl) };
        await updateDoc(docRef, updateField);

        return { success: true };
    } catch (error) {
        console.log(`Erreur lors de la suppression de la vidéo`, error);
        return { success: false, error: error.message };
    }
};

// delete image
export const deleteImage = async (type, imageUrl, pageId = null) => {
    const uid = auth.currentUser.uid;

    try {
        if (!imageUrl) {
            throw new Error("URL de l'image est undefined");
        }

        let docRef, updateField;

        switch (type) {
            case 'user':
                docRef = doc(db, `users/${uid}`);
                updateField = { photoURL: null };
                break;
            case 'page':
                if (!pageId) throw new Error("pageId est nécessaire pour le type 'page'");
                docRef = doc(db, `pages/${pageId}`);
                updateField = { images: arrayRemove(imageUrl) };
                break;
            case 'cover':
                if (!pageId) throw new Error("pageId est nécessaire pour le type 'cover'");
                docRef = doc(db, `pages/${pageId}`);
                updateField = { coverURL: null };
                break;
            case 'main':
                if (!pageId) throw new Error("pageId est nécessaire pour le type 'main'");
                docRef = doc(db, `pages/${pageId}`);
                updateField = { photoURL: null };
                break;
            default:
                throw new Error("Type d'image non pris en charge");
        }

        // Supprimer la référence de l'image dans Firestore
        await updateDoc(docRef, updateField);

        // Supprimer l'image de Firebase Storage
        const imageRef = storageRef(imageDb, imageUrl);
        await deleteObject(imageRef);

        return { success: true };
    } catch (error) {
        console.log(`Erreur lors de la suppression de l'image (${type})`, error);
        return { success: false, error: error.message };
    }
};

// reset qrcode page
export const resetQrCodePage = async (idPage, qrCode) => {
    try {
        const pageRef = doc(db, "pages", idPage);
        await updateDoc(pageRef, {
            qrcodeID: null
        });
        const qrCodeRef = doc(db, "qrcodes", qrCode);
        await updateDoc(qrCodeRef, {
            available: true,
            owner: null,
            page: null,
        });

        return { success: true };
    } catch (error) {
        return { success: false, error: error };
    }
};

// ############################## FIREBASE AUTHENTICATION #######################################

// logout
export const logOutUser = () => {
    signOut(auth).then(() => {
        // Navigate('/');
        // console.log('deconnexion')
    }).catch((error) => {
        console.log(error)
    });
}

// login by email
export const userLoginByEmail = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
        return { success: true };
    } catch (err) {
        console.log(err);
        return { success: false, error: err };
    }
}

// login with google
export const userLoginByGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
        await signInWithPopup(auth, provider)
            .then((result) => {
                GoogleAuthProvider.credentialFromResult(result);
            }).catch((error) => {
                console.log(error);
            });
    } catch (err) {
        console.log(err);
    }
}


// reset password
export const resetPassword = async (email) => {
    try {
        const exist = await checkIfEmailExist(email);
        if (exist.length === 0) return { success: false, error: 'Email not found' };
        await sendPasswordResetEmail(auth, email);
        return { success: true };
    } catch (err) {
        console.log(err);
        return { success: false, error: err };
    }
}
export const checkIfEmailExist = async (email) => {
    try {
        const q = query(collection(db, "users"), where("email", "==", email));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const results = [];
            querySnapshot.docs.forEach(doc => {
                results.push(doc.data());
            });
            return results;
        }
        return [];
    } catch (error) {
        console.error("Error fetching code", error);
        return null;
    }
}

// register by email
export const userRegisterByEmail = async (email, password, name) => {
    try {
        const userAuth = await createUserWithEmailAndPassword(auth, email, password);
        await createUserDocument({ ...userAuth.user, displayName: name });
        return { success: true };
    } catch (err) {
        console.log(err);
        return { success: false, error: err };
    }
}

// user data
export const getUserDocument = async (uid) => {
    try {
        const userDoc = await getDoc(doc(db, "users", uid));
        return userDoc.exists() ? userDoc.data() : null;
    } catch (error) {
        console.error("Error fetching user", error);
        return null;
    }
};

// create user document
export const createUserDocument = async (user) => {
    try {
        await setDoc(doc(db, "users", user.uid), {
            uid: auth.currentUser.uid,
            displayName: user.displayName || 'Nouveau utilisateur',
            email: user.email,
            photoURL: user.photoURL ? user.photoURL : null,
            status: "new",
            created: new Date()
        });
    } catch (error) {
        console.error("Error creating user document", error);
    }
};

// ############################## ADMIN #######################################

// get all qrcodes 
export const allQrCodes = async () => {
    try {
        const q = query(collection(db, "qrcodes"));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const results = [];

            querySnapshot.forEach((doc) => {
                results.push(doc.data());
            });

            return results;
        }

        return [];
    } catch (error) {
        console.error("Erreur lors de la récupération des données", error);
        return null;
    }
};

export const updateQRCodesExportedStatus = async (id, exportedStatus) => {
    const qrCodeDocRef = doc(db, "qrcodes", id);
    await updateDoc(qrCodeDocRef, { exported: exportedStatus });
}

export const createQRCodeList = async (code, type) => {
    try {
        const qrCodeData = {
            uid: code,
            owner: null,
            available: true,
            page: null,
            url: `https://app.dernierinstant.com/memory/${code}`,
            createdAt: new Date(),
            exported: false
        };

        if (type === 'animal') {
            qrCodeData.animal = true;
        } else if (type === 'entreprise') {
            qrCodeData.entreprise = true;
        }

        await setDoc(doc(db, "qrcodes", code), qrCodeData);
        console.log("QR Code successfully created with type:", type);
    } catch (error) {
        console.error("Error creating QR Code document", error);
    }
};

export const createQRCodeListConvert = async (code) => {
    try {
        await setDoc(doc(db, "qrcodes", code), {
            uid: code,
            owner: null,
            available: true,
            page: null,
            url: `https://app.dernierinstant.com/memory/${code}`,
            createdAt: new Date(),
            exported: true,
        });
    } catch (error) {
        console.error("Error creating user document", error);
    }
};

export const convertPage = async (data) => {

    try {
        const pagesCollectionRef = collection(db, "pages");
        const docRef = await addDoc(pagesCollectionRef, {
            biographie: data.biographie,
            birth: data.birth,
            created: new Date(),
            death: data.death,
            id: data.id,
            images: data.images,
            isComment: true,
            isShare: true,
            isModerate: true,
            links: data.links,
            name: data.name,
            photoURL: data.photoURL,
            owner: data.owner,
            status: 'hidden',
            whitelist: null
        });
        await setDoc(docRef, { id: docRef.id }, { merge: true });
        return docRef;
    } catch (error) {
        console.error("Error creating page document", error);
        return { success: false, error: error };
    }
}

export const checkUserPageByUID = async (uid) => {
    try {
        const q = query(collection(db, "pages"), where("owner", "==", uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const results = [];
            querySnapshot.docs.forEach(doc => {
                results.push(doc.data());
            });
            return results;
        }
        return [];
    } catch (error) {
        console.error("Error fetching code", error);
        return null;
    }
}

export const createPageByModerator = async (name, status, uid) => {
    try {
        const pagesCollectionRef = collection(db, "pages");
        const docRef = await addDoc(pagesCollectionRef, {
            owner: uid,
            name: name,
            status: status,
            created: new Date(),
            qrcodeID: null,
            isComment: true,
            isModerate: true,
            isShare: true,
            password: Math.floor(Math.random() * 9999)
        });
        // update page id
        await setDoc(docRef, { id: docRef.id }, { merge: true });

        return docRef.id;
    } catch (error) {
        console.error("Error creating page document", error);
        return null;
    }
};

export const deleteMedaillon = async (id) => {
    try {
        const userRef = doc(db, "qrcodes", id);
        await deleteDoc(userRef);
        return { success: true };
    } catch (error) {
        return { success: false, error: error.message };
    }
};


export const resetPasswordModerator = async (email) => {
    try {
        // const exist = await checkIfEmailExist(email);
        // if (exist.length === 0) return { success: false, error: 'Email not found' };
        await sendPasswordResetEmail(auth, email);
        return { success: true };
    } catch (err) {
        console.log(err);
        return { success: false, error: err };
    }
}

export const linkQrCodeToPageByModerator = async (idPage, qrCode, uid) => {
    try {
        const pageRef = doc(db, "pages", idPage);
        await updateDoc(pageRef, {
            qrcodeID: qrCode
        });
        const qrCodeRef = doc(db, "qrcodes", qrCode);
        await updateDoc(qrCodeRef, {
            available: false,
            owner: uid,
            page: idPage,
        });

        return { success: true };
    } catch (error) {
        return { success: false, error: error };
    }
};


/// ADMIN V2

// get all registered users
export const getAllUsers = async () => {
    try {
        const q = query(collection(db, "users"));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const results = [];

            querySnapshot.forEach((doc) => {
                results.push(doc.data());
            });

            return results;
        }

        return [];
    } catch (error) {
        console.error("Erreur lors de la récupération des données", error);
        return null;
    }
};


// SECONDARY MEDALLION
// export const importSecondaryMedallion = async (pageID, qrcodeID) => {
//     try {
//         const userRef = doc(db, "pages", pageID);
//         await updateDoc(userRef, {
//             secondary: qrcodeID
//         });
//         return { success: true };
//     } catch (error) {
//         return { success: false, error: error.message };
//     }
// };

// create comment
export const importSecondaryMedallion = async (pageID, qrcodeID) => {
    try {
        const linkIntoPage = await linkSecondaryMedallion(pageID, qrcodeID);
        if (!linkIntoPage.success) return { success: false, error: linkIntoPage.error };

        console.log(linkIntoPage);
        const userRef = doc(db, "pages", pageID);
        await updateDoc(userRef, {
            secondary: arrayUnion(qrcodeID)
        });
        return { success: true };
    } catch (error) {
        return { success: false, error: error.message };
    }
};

// delete comment
export const removeSecondaryMedallion = async (pageID, qrcodeID) => {
    try {
        await resetSecondaryMedallion(qrcodeID);
        const userRef = doc(db, "pages", pageID);
        await updateDoc(userRef, {
            secondary: arrayRemove(qrcodeID)
        });
        return { success: true };
    } catch (error) {
        return { success: false, error: error.message };
    }
};

// reset secondary medallion
export const resetSecondaryMedallion = async (qrCode) => {
    try {
        const qrCodeRef = doc(db, "qrcodes", qrCode);
        await updateDoc(qrCodeRef, {
            available: true,
            owner: null,
            page: null,
        });

        return { success: true };
    } catch (error) {
        return { success: false, error: error };
    }
};

export const linkSecondaryMedallion = async (idPage, qrCode) => {
    try {
        const uid = auth.currentUser.uid;
        const qrCodeRef = doc(db, "qrcodes", qrCode);
        await updateDoc(qrCodeRef, {
            available: false,
            owner: uid,
            page: idPage,
        });

        return { success: true };
    } catch (error) {
        return { success: false, error: error };
    }
};